import React, { Component } from "react";
import "./App.scss";
import route from "./route";



class App extends Component {
  render() {
    return (<div className="App">
    
      <div >{route}</div>
    </div>);
  }
}

export default App;
